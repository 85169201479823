jQuery('#app-slider').owlCarousel({
  loop: true,
  nav: false,
  responsive: {
    0: {
      items: 1
    },
    500: {
      items: 1,
      margin: 95,
    },
    1024: {
      items: 1,
      stagePadding: 252,
      margin: 95,
    },
    1170: {
      items: 1,
      stagePadding: 100,
      margin: 70,
    },
    1280: {
      items: 1,
      stagePadding: 186,
      margin: 135,
    },
    1600: {
      items: 1,
      stagePadding: 252,
      margin: 95,
    },
    1680: {
      items: 1,
      stagePadding: 352,
      margin: 95,
    },
    2250: {
      items: 1,
      stagePadding: 650,
      margin: 100,
    },
    2600: {
      items: 1,
      stagePadding: 800,
      margin: 100,
    },
    2900: {
      items: 2,
      stagePadding: 400,
      margin: 50,
    },
    3300: {
      items: 3,
      stagePadding: 0,
      margin: 50,
    },
  }
});

jQuery('#tool-slider').owlCarousel({
  loop: true,
  nav: false,
  margin: 0,
  responsive: {
    0: {
      items: 1
    },
    800: {
      items: 2
    },
    1000: {
      items: 3,
    }
  }
});

function toggleIt(el, cls) {
  el.classList.toggle(cls);
}

function SwapDivsWithClick(div1, div2) {
  d1 = document.getElementById(div1);
  d2 = document.getElementById(div2);
  if (d2.style.display == "none") {
    d1.style.display = "none";
    d2.style.display = "block";
  } else {
    d1.style.display = "block";
    d2.style.display = "none";
  }
}